import React from 'react';
import {Theme} from '@mui/material/styles';
import {createStyles, withStyles, WithStyles} from '@mui/styles';
import {withTranslation, WithTranslation} from 'react-i18next';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';

import SectionCard from '../Partials/SectionCard';
import NormSVG from '../SVGs/Norm';
import {Norm2018, Norm2022} from "../../Norms";
import {FormControl, InputLabel} from "@mui/material";

const NormStyles = (theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(4) + ' auto'
    },
    button: {
        marginTop: theme.spacing(6),
        marginRight: theme.spacing(6)
    }
});

const languages = ['de', 'be', 'lu'];

interface NormProps extends WithStyles<typeof NormStyles>, WithTranslation {
    setNorm: any;
}

interface NormState {
    currentLanguage: string;
}

class Norm extends React.Component<NormProps, NormState> {
    constructor(props: NormProps) {
        super(props);
        this.state = {
            currentLanguage: 'de'
        };
    }


    handleLanguageChange = (event: SelectChangeEvent<string>) => {
        this.setState({currentLanguage: event.target.value});
    };

    render() {
        const {classes, t} = this.props;
        const {currentLanguage} = this.state;

        const country = currentLanguage === 'be' ? t('norm.language.be') : t('norm.language.lu');
        return (
            <SectionCard rootClass={classes.root}
                         avatar={<NormSVG/>}
                         title={<Typography variant="h2">{t('norm.headline')}</Typography>}>
                <FormControl variant="standard">
                    <InputLabel>{t('norm.plantLocation')}</InputLabel>
                    <Select value={currentLanguage} onChange={this.handleLanguageChange}>
                        {languages.map((language) => (
                            <MenuItem key={language} value={language}>
                                {t(`norm.language.${language}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {currentLanguage !== 'de' ?
                    <Typography dangerouslySetInnerHTML={{ __html: t('norm.disclaimer', { country }) }} /> :
                    <Typography dangerouslySetInnerHTML={{__html: t('norm.bodytext')}}/>
                }

                <Button
                    className={classes.button}
                    variant="contained" color="primary"
                    onClick={() => this.props.setNorm(new Norm2018())}>
                    {t('norm.button.norm_2018')}
                </Button>

                <Button
                    className={classes.button}
                    variant="contained" color="primary"
                    disabled={currentLanguage !== 'de'}
                    onClick={() => this.props.setNorm(new Norm2022())}>
                    {t('norm.button.norm_2022')}
                </Button>
            </SectionCard>
        );
    }
}

const styledNorm = withStyles(NormStyles)(Norm);

export default withTranslation()(styledNorm);